import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BasicModalComponent } from "./basic-modal/basic-modal.component";
import { InputModule } from "../input/input.module";
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog";
import { GridConfigModalComponent } from "./grid-config-modal/grid-config-modal.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RedistributionModalComponent } from "./redistribution-modal/redistribution-modal.component";
import { CalendarModule } from "primeng/calendar";
import { TooltipModule } from "primeng/tooltip";
import { TutorialVideoModalComponent } from "./tutorial-video-modal/tutorial-video-modal.component";
import { PostModalComponent } from "./post-modal/post-modal.component";
import { CKEditorModule } from "ckeditor4-angular";
import { DropdownModule } from "primeng/dropdown";
import { CheckboxModule } from "primeng/checkbox";
import { WidgetEditModalComponent } from "./widget-edit-modal/widget-edit-modal.component";
import { DDModule } from "../dropdown/dropdown.module";
import { ChkModule } from "../checkbox/checkbox.module";
import { DropdownGenericModule } from "../dropdown-generic/dropdown-generic.module";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InputModule,
    CalendarModule,
    DynamicDialogModule,
    TooltipModule,
    CKEditorModule,
    DropdownModule,
    CheckboxModule,
    DDModule,
    ChkModule,
    DropdownGenericModule
  ],
  declarations: [
    BasicModalComponent,
    GridConfigModalComponent,
    RedistributionModalComponent,
    TutorialVideoModalComponent,
    PostModalComponent,
    WidgetEditModalComponent,
  ],
  exports: [
    BasicModalComponent,
    GridConfigModalComponent,
    RedistributionModalComponent,
    TutorialVideoModalComponent,
    PostModalComponent,
    WidgetEditModalComponent,
    DynamicDialogModule,
  ],
  providers: [DialogService],
})
export class ModalModule {}
