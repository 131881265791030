import { Component, Input, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { GridConfigModalOptions } from "src/app/shared/services/grid-config-modal.service";
import { GridService } from "src/app/shared/services/grid.service";

@Component({
  selector: "app-grid-config-modal",
  templateUrl: "./grid-config-modal.component.html",
  styleUrls: ["./grid-config-modal.component.scss"],
})
export class GridConfigModalComponent implements OnInit {
  @Input() title: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() options: GridConfigModalOptions = {
    gridName: '',
    stageId: '',
    columns: []
  };
  json = null;
  disableButton = false;
  invalidJSON = false;

  constructor(public dialogRef: DynamicDialogRef, public config: DynamicDialogConfig, private gridService: GridService) {}

  ngOnInit() {
    this.title = this.config.data?.title;
    this.btnOkText = this.config.data?.btnOkText;
    this.btnCancelText = this.config.data?.btnCancelText;
    this.options = this.config.data?.options;
    this.getGridConfig();
  }

  async getGridConfig() {
    if (!this.options.columns || this.options.columns.length === 0) {
      const { columns } = await this.gridService.GetGridConfig(this.options.gridName, this.options.stageId).toPromise();
      this.options.columns = columns;
    }
    this.json = JSON.stringify(this.options.columns, null, 4);
  }

  public cancel() {
    this.dialogRef.close(false);
  }

  public async save() {
    await this.gridService.SaveGridConfig({
      stageId: this.options.stageId,
      gridName: this.options.gridName,
      columns: JSON.parse(this.json)
    }).toPromise();
    this.dialogRef.close(true);
  }

  public dismiss() {
    this.dialogRef.close(null);
  }

  isJsonValid(json: string) {
    try {
      JSON.parse(json);
      return true;
    } catch (error) {
      return false;
    }
  }

  onInput(json: string) {
    this.invalidJSON = !json ? false : !this.isJsonValid(json);
  }

}
