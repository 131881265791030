import { Component, Input, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { CompetitionInformation } from "src/app/shared/model/common.model";
import { PublicConfigService } from "src/app/shared/services/public.config.service";
import { StorageService } from "src/app/shared/services/storage.service";

@Component({
  selector: "app-basic-modal",
  templateUrl: "./basic-modal.component.html",
  styleUrls: ["./basic-modal.component.scss"],
})
export class BasicModalComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() status?: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() additionalMessage: string;
  @Input() captchaCheck?: boolean = false;
  inputError: boolean;
  noError: boolean;
  inputModel;
  disableButton: boolean = false;

  constructor(
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private publicConfig: PublicConfigService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    const supportEmailAddress = this.storage.retrieve(CompetitionInformation.supportEmailAddress) || '';
    this.title = this.config.data?.title;
    this.message = this.config.data?.message?.replace(/https:\/\/febaseurl/g, this.publicConfig.feBaseUrl);
    if (supportEmailAddress) {
      this.message = this.message.replace(
        '{supportEmailAddress}',
        `<a href="mailto:${supportEmailAddress}">${supportEmailAddress}</a>`
      );
    }
    this.btnOkText = this.config.data?.btnOkText;
    this.btnCancelText = this.config.data?.btnCancelText;
    this.additionalMessage = this.config.data?.additionalMessage;
    this.captchaCheck = this.config.data?.captchaCheck;
    if (this.captchaCheck) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
  }

  public decline() {
    this.dialogRef.close(false);
  }

  public accept() {
    this.dialogRef.close(true);
  }

  public dismiss() {
    this.dialogRef.close(false);
  }

  focusOutHandler(e) {
    if (this.captchaCheck == true) {
      if (e !== "carrot") {
        this.inputError = true;
        this.noError = false;
        this.disableButton = true;
      } else {
        this.inputError = false;
        this.noError = true;
        this.disableButton = false;
      }
    }
  }

  keyUpHandler(e) {
    if (this.captchaCheck == true) {
      if (e !== "carrot") {
        this.inputError = true;
        this.noError = false;
        this.disableButton = true;
      } else {
        this.inputError = false;
        this.noError = true;
        this.disableButton = false;
      }
    }
  }
}
