import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormioAppConfig,
  FormioCustomComponent,
  FormioEvent,
} from "@formio/angular";
import { TreeNode } from "primeng/api";
import { Tree } from "primeng/tree";
import { BehaviorSubject, Observable, of, Subject, Subscription } from "rxjs";
import { debounceTime, delay, map, switchMap, tap } from "rxjs/operators";
import { DataService } from "src/app/shared/services/data.service";
import { TaxonomyValue } from "./model/taxonomy.model";
import { PublicConfigService } from "src/app/shared/services/public.config.service";

@Component({
  selector: "app-taxonomy",
  templateUrl: "./taxonomy.component.html",
  styleUrls: ["./taxonomy.component.scss"],
})
export class TaxonomyComponent
  implements FormioCustomComponent<string>, OnInit, OnDestroy {
  selected: TreeNode[] = [];
  nodes: TreeNode[] = [];
  showMaxSelectionError: boolean = false;
  showFilter: boolean = true;

  private initialized = false;

  private _value: string;
  @Input()
  public set value(v: string) {
    this._value = v;
    this.init();
  }

  public get value(): string {
    return this._value;
  }

  public get isValid(): boolean {
    let result = false;
    if (!this.required && (this.isControlEmpty || this.selected.length <= Number(this.maxSelection))) {
      result = true;
    }
    else if (this.required && !this.isControlEmpty && this.selected.length <= Number(this.maxSelection)) {
      result = true;
    }
    return result;
  }

  // used to check if the control is empty or not
  public isControlEmpty: boolean;

  @Input()
  disabled: boolean;

  @Output()
  valueChange = new EventEmitter<string>();

  @Output()
  formioEvent = new EventEmitter<FormioEvent>();

  @Input()
  maxSelection: string;

  @Input()
  hideKeys: boolean;

  @Input()
  required: boolean;

  @Input()
  public set taxonomyDatasetName(v: string) {
    this._taxonomyDatasetName = v;
    this._nodes$.next();
  }
  public get taxonomyDatasetName(): string {
    return this._taxonomyDatasetName;
  }

  get loading$() {
    return this._loading$.asObservable();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _nodes$ = new Subject<TreeNode[]>();
  private _taxonomyDatasetName: string;
  private nodesSubscription$: Subscription;
  @ViewChild(Tree)
  private tree: Tree;
  private filter = "";

  constructor(
    private publicConfigService: PublicConfigService,
    private dataService: DataService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.nodesSubscription$ = this._nodes$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this.getNodes()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((nodes) => {
        nodes = nodes || [];
        nodes.forEach((node) => {
          if (!node.selectable) {
            node.styleClass = "hide-checkbox";
            node.data = { selectable: false };
          }
        });
        this.nodes = nodes;
        this.init();
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy() {
    this.nodesSubscription$.unsubscribe();
  }

  selectNode(node: TreeNode) {
    this.selected = this.selected || [];
    const existingNode = this.selected.find((n) => n.key === node.key);
    if (existingNode) {
      this.unselectNode(node);
    } else {
      this.selected = [...this.selected, node];
      this.showMaxSelectionError =
        this.selected.length > Number(this.maxSelection);
      this.selected = this.selected.slice(0, Number(this.maxSelection));
      this.toggleSelectableNodes();
      this.isControlEmpty = false;
      this.setValue();
    }
  }

  unselectNode(node: TreeNode) {
    this.selected = this.selected.filter((n) => n.key !== node.key);
    this.showMaxSelectionError =
      this.selected.length >= Number(this.maxSelection);
    this.toggleSelectableNodes();
    if (this.selected.length === 0) {
      this.isControlEmpty = true;
    };
    this.setValue();
  }

  nodeClicked(node: TreeNode) {
    const nodeSelectionDisabled = this.isSelectionDisabled(node);
    this.showMaxSelectionError =
      this.selected.length >= Number(this.maxSelection) &&
      !node.selectable &&
      !nodeSelectionDisabled;

    if (nodeSelectionDisabled) {
      if (!node.expanded) {
        node.expanded = true;
      } else {
        node.expanded = false;
      }
    }
  }

  expandAll() {
    this.clearFilter();
    this.nodes.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  collapseAll() {
    this.nodes.forEach((node) => {
      this.expandRecursive(node, false);
    });
  }

  collapseNode(node: TreeNode) {
    this.expandRecursive(node, false);
  }

  clearSelection() {
    this.selected = [];
    this.showMaxSelectionError = false;
    this.toggleSelectableNodes();
    this.isControlEmpty = true;
    this.setValue();
  }

  canExpand() {
    return !this.nodes.every(
      (n) => (n.children.length && n.expanded) || !n.children.length
    );
  }

  canCollapse() {
    return (
      !this.filter && this.nodes.some((n) => n.children.length && n.expanded)
    );
  }

  canClearSelection() {
    return this.selected.length > 0;
  }

  expandNode(node: TreeNode) {
    while (node.parent) {
      node = node.parent;
      node.expanded = true;
    }
  }

  showDot(node: TreeNode) {
    return !node.expanded && this.isNodeSelectedRecursive(node, node.key);
  }

  treeFiltered(event: any) {
    this.filter = event.filter;
  }

  isSelectionDisabled(node: TreeNode) {
    return node.data && node.data.selectable === false;
  }

  private setValue() {
    const taxonomyData: TaxonomyValue = {
      value: this.selected.map((n) => {
        return { ...n, children: [], parent: null };
      }),
      hideKeys: this.hideKeys,
      valid: this.isValid,
    };
    this.value = JSON.stringify(taxonomyData);
    this.valueChange.emit(this.value);
    this.formioEvent.emit({ eventName: "change", data: { isChanged: true } });
  }

  private getNodes() {
    if (this.taxonomyDatasetName && !this.disabled) {
      
      let url = `${this.publicConfigService.rpBaseUrl}/api/dataset/taxonomy/${this.taxonomyDatasetName}`

      return this.dataService.get(url).pipe(
        map((response) => {
          let nodes = response.data;
          nodes = nodes.map((node) => {
            node.children = nodes.filter((n) => n.parentKey === node.key);
            return node;
          });
          return nodes.filter((n) => !n.parentKey);
        })
      );
      
    } else {
      // no need to return nodes if control is disabled
    }
    return of([]);
  }

  private expandRecursive(node: TreeNode, expanded: boolean) {
    node.expanded = expanded;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, expanded);
      });
    }
  }

  private isNodeSelectedRecursive(node: TreeNode, skip: string) {
    if (this.selected.some((n) => n.key === node.key) && skip !== node.key) {
      return true;
    }
    if (node.children) {
      for (const childNode of node.children) {
        if (this.isNodeSelectedRecursive(childNode, skip)) {
          return true;
        }
      }
    }

    return false;
  }

  private selectableRecursive(node: TreeNode, selectable: boolean) {
    if (
      !this.selected.some((n) => n.key === node.key) &&
      !this.isSelectionDisabled(node)
    ) {
      node.selectable = selectable;
    }
    if (node.children) {
      node.children.forEach((childNode) => {
        this.selectableRecursive(childNode, selectable);
      });
    }
  }

  private toggleSelectableNodes() {
    const maxSelectionReached =
      this.selected.length === Number(this.maxSelection);
    this.nodes.forEach((node) => {
      this.selectableRecursive(node, !maxSelectionReached);
    });
  }

  private clearFilter() {
    this.filter = "";
    this.showFilter = false;
    this.tree.filteredNodes = null;
    this.changeDetector.detectChanges();
    this.showFilter = true;
  }

  private init() {
    // check if control has value, if nodes are loaded and if it's already initialized
    if (this.value && this.nodes.length && !this.initialized) {
      const selected = JSON.parse(this.value).value;
      this.selected = this.nodes
        .reduce((a, n) => [...a, n, ...n.children], [])
        .filter((n) =>
          selected.some((s) => s.key === n.key || s.label === n.label)
        );
      this.isControlEmpty = false;
      this.initialized = true;
    } else if (this.value && !this.nodes.length && !this.initialized) {
      // check if control has value, if nodes are not loaded and if it's already initialized
      // this means we are in view only mode
      this.selected = JSON.parse(this.value).value;
      this.isControlEmpty = false;
      this.initialized = true;
    }
  }
}
