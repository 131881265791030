<div class="budget-wrap" [ngClass]="{'has-empty-value': isEmpty, 'disabled': disabled}">
  <div class="table-wrap">
    <p-table [value]="budgets" [(selection)]="selectedBudgets">
      <ng-template pTemplate="header">
        <tr>
          <td class="p-element" style="width: 4%"></td>
          <td class="p-element" style="width: 70%">Budget Item</td>
          <td class="p-element" style="width: 20%">Budget Amount</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-budget>
        <tr [ngClass]="{ 'soft-errored': !budget.budgetItemValid || !budget.budgetValueValid }">
          <td class="table-checkbox">
            <app-checkbox-button [(state)]="budget.selected" (stateChange)="onRowSelect($event, budget)"
              [disabled]="disabled"></app-checkbox-button>
          </td>
          <td pEditableColumn [pEditableColumnDisabled]="disabled ? true : false">
            <p-cellEditor #budgetItems>
              <ng-template pTemplate="input">
                <input pInputText autoResize="autoResize" type="text" (change)="handleChange()"
                  (keydown)="handleEnter($event, budget)" (keyup)="removeLastWords(budget)" [disabled]="disabled"
                  [readonly]="disabled" [(ngModel)]="budget.budgetItem" placeholder="Budget item short description" />
              </ng-template>
              <ng-template pTemplate="output">
                <div [ngClass]="{ output: budget.budgetItem === '' }">
                  {{ getBudgetItemValue(budget.budgetItem) }}
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn [pEditableColumnDisabled]="disabled ? true : false" id="{{ 'budgetValue-' + budget.id }}">
            <p-cellEditor #budgetValues>
              <ng-template pTemplate="input">
                <input pInputText type="number" (change)="handleChange()" (keydown)="handleEnterVal($event, budget)"
                  (keyup)="removeLastDigits(budget)" [(ngModel)]="budget.budgetValue" placeholder="$ 0"
                  [disabled]="disabled" min="0" oninput="validity.valid||(value='');" />
              </ng-template>
              <ng-template pTemplate="output">
                <div [ngClass]="{ output: budget.budgetValue === '' }">
                  {{ getBudgetValue(budget.budgetValue) }}
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer" let-car>
        <tr [ngClass]="{ errored: !this.totalIsValid }">
          <td></td>
          <td>
            {{ totalRequirements ? totalRequirements : "Total:" }}
          </td>
          <td>
            $ {{ getTotal() }}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="footer-buttons-wrap" *ngIf="!disabled">
      <div class="btn-wrap">
        <p-button styleClass="button button-primary button-primary--medium" (click)="addNew()"
          [disabled]="this.budgets.length === 50">
          <span class="material-icons"> add </span>
          Add
        </p-button>
      </div>
      <div class="btn-wrap" [pTooltip]="showDeleteTooltip(this.selectedBudgets.length)">
        <p-button styleClass="button button-delete button-delete--medium" (click)="deleteSelectedBudgets()"
          [disabled]="this.budgets.length === this.selectedBudgets.length || this.selectedBudgets.length === 0">
          <span class="material-icons"> delete </span>
          Delete
        </p-button>
      </div>
    </div>
  </div>
</div>