import { Injectable } from "@angular/core";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { FormSubmission as FormSubmission } from "../model/form-submission";
import { HttpClient } from "@angular/common/http";
import { DataService } from "../../shared/services/data.service";
import { PublicConfigService } from "../../shared/services/public.config.service";

@Injectable({
  providedIn: "root",
})
export class FormSubmissionDataService extends DefaultDataService<FormSubmission> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private dataService: DataService,
    private publicConfigService: PublicConfigService
  ) {
    super("FormSubmission", http, httpUrlGenerator);
  }

  upsert(submission: FormSubmission) {
    const url = `${this.publicConfigService.rpBaseUrl}/api/${submission.stageId}/formsubmission/${submission.userId}`;
    return this.dataService.post(url, submission);
  }

  getSubmission(stageId: string, userId: string) {
    const url = `${this.publicConfigService.rpBaseUrl}/api/${stageId}/formsubmission/${userId}`;
    return this.dataService.get(url);
  }

  getSubmissionForDisplay(stageId: string, userId: string) {
    const url = `${this.publicConfigService.rpBaseUrl}/api/${stageId}/display/formsubmission/${userId}`;
    return this.dataService.get(url);
  }
}
