<div *ngIf="!disabled" class="location" [ngClass]="{'has-empty-value': isControlEmpty}">
  <div *ngFor="let field of fields" ref="component"
    class="form-group has-feedback formio-component formio-component-select formio-component-select" id="eys2dsl">
    <label for="eys2dsl-select" class="col-form-label location-label">
      {{ field.name }}
    </label>
    <div class="choices form-group formio-choices" data-type="select-one" dir="ltr" tabindex="-1" role="combobox"
      aria-autocomplete="list" aria-haspopup="true" aria-expanded="false"
      (click)="$event.stopPropagation(); setActiveField(field)" [ngClass]="{
        'opened': field.active
      }">
      <div class="form-control ui fluid selection dropdown" tabindex="0" [ngClass]="{
          'is-invalid': !field.valid && isDirty
        }">
        <select id="eys2dsl-select" lang="en" class="form-control choices__input" type="text" name="data[select]"
          ref="selectContainer" dir="ltr" hidden="" tabindex="-1" data-choice="active"></select>
        <div *ngIf="field.location.text" class="choices__list choices__list--single">
          <span class="choices__item">{{ field.location.text }}</span>
          <button type="button" class="choices__button" (click)="$event.stopPropagation(); clearFieldValue(field)">
            Remove item
          </button>
        </div>
      </div>
      <div class="choices__list choices__list--dropdown" aria-expanded="false" [ngClass]="{
          visible: field.active
        }">
        <input #search type="text" class="choices__input choices__input--cloned" autocomplete="off" autocapitalize="off"
          spellcheck="false" role="textbox" aria-autocomplete="list" aria-label="false" placeholder="Type to search"
          [disabled]="disabled" (click)="$event.stopPropagation()" [(ngModel)]="field.search"
          (ngModelChange)="onSearchChange()" [attr.level]="field.level" />
        <div class="choices__list" role="listbox" [ngClass]="{'searching': isSearching}">
          <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div *ngFor="let choice of field.choices" class="choices__item choices__item--choice"
            (click)="$event.stopPropagation(); setFieldLocation(field, choice)">
            {{ choice.text }}
          </div>
        </div>
      </div>
    </div>
    <input tabindex="-1" ref="autocompleteInput" class="formio-select-autocomplete-input" type="text" />
  </div>
  <div *ngIf="validationMessage && isDirty" class="formio-errors invalid-feedback" ref="messageContainer">
    <div class="form-text error">{{ validationMessage }}</div>
  </div>
</div>

<div *ngIf="disabled" class="location-disabled-wrap">
  <span class="asterix" *ngIf="required">*</span>
  <div *ngFor="let field of fields" class="location-disabled-dropdown-wrap">
    <div class="location-disabled-dropdown">
      <span class="content" *ngIf="field.location.text">{{ field.location.text }}</span>
      <span class="label" *ngIf="field.name && !field.location.text">{{ field.name }}</span>
    </div>
  </div>
</div>