import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router} from "@angular/router";
import { DateTime } from "luxon";
import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";
import { NotificationDialogService } from "src/app/shared/services/notification-dialog.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { TabTitleService } from "src/app/shared/services/tab-title.service";
import { AdminReviewService } from "../../admin-review.service";

@Component({
  selector: "app-admin-review-edit-review",
  templateUrl: "./admin-review-edit-review.component.html",
  styleUrls: ["./admin-review-edit-review.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AdminReviewEditReviewComponent implements OnInit {

  // header
  userFirstName: string;
  userLastName: string;
  userId: string;
  userForms: any[] = [];
  userActions: any[] = [];

  // content
  reviewId: string;
  userAppNumber: string;
  generalQuestionsArr: any[] = [];
  additionalReviewArr: any[] = [];

  // page
  stageUrl: string | any[];

  // step/action
  preventNext: boolean;

  // stored data (from localStorage)
  storedState: any = this.storageService.retrieve('adminReviewStoredState');

  constructor(
    private adminReviewService: AdminReviewService,
    private storageService: StorageService,
    public activateRouter: ActivatedRoute,
    private router: Router,
    private notificationDialogService: NotificationDialogService,
    private tabTitleService: TabTitleService
  ) {}

  ngOnInit() {
    // TODO get stage name from API
    this.activateRouter.params.subscribe(params => {
      this.tabTitleService.setTabTitle('Edit - Admin Review');
      this.stageUrl = this.router.url.split('/');
      this.stageUrl.pop();
      this.stageUrl.pop();
      this.stageUrl.pop();
      this.stageUrl = this.stageUrl.join('/');
      this.reviewId = params.reviewId;
  
      forkJoin([this.getReviewHeader(this.reviewId), this.getReviewDetails(this.reviewId)]).subscribe();
    })
  }

  async getReviewHeader(reviewId) {
    const response: any = await this.adminReviewService.GetAdminReviewReviewHeader(reviewId, 'edit').toPromise();
    this.userFirstName = response.data.firstName;
    this.userLastName = response.data.lastName;
    this.userId = response.data.userId;

    if(response.data.forms) {
      response.data.forms.forEach(element => {
        const obj = {
          name: element.formName,
          href: `form/${element.stageId}/display/${this.userId}`
        };
        this.userForms.push(obj);
      });
    }

    if (response.data.actions) {
      response.data.actions.forEach(element => {
        if (element.name === 'MarkFinal') element.name = 'Mark Final';
        if (element.name === 'Discard') {
          element.style = 'discard';
        } else {
          element.style = 'normal';
        }
        const obj = {
          name: element.name,
          value: element.value,
          style: element.style
        }
        this.userActions.push(obj);
      });
    }
  }

  performAction(actionValue, actionName) {
    this.preventNext = false;
    let action = {
      action: actionValue,
      questionsAndAnswers: []
    };
    switch (actionName) {
      case 'Discard':
        this.generalQuestionsArr.forEach(element => {
          const compiledAnswer = {
            questionId: element.id,
            title: element.title,
            answer: element.answer,
            comments: element.comments
          }
          action.questionsAndAnswers.push(compiledAnswer);
        });
        if (this.additionalReviewArr) {
          this.additionalReviewArr.forEach(element => {
            const compiledAnswer = {
              questionId: element.id,
              title: element.title,
              answer: element.answer,
              comments: element.comments
            }
            action.questionsAndAnswers.push(compiledAnswer);
          });
        }
        break;
      case 'Next':
        this.generalQuestionsArr.forEach(element => {
          if (element.answer === 'no' && element.comments.length < 1) {
            this.preventNext = true;
            this.notificationDialogService.confirm('Unable to proceed', '', 'Please add a comment for each General Question answered with No.', 'Ok', '400px')
            .then((confirmed) => {
              // if confirmed === true - user confirmed, if false - user clicked cancel button
              if (confirmed) {
                // console.log('confirm leave');
              } else {
                // console.log('do not confirm leave');
              };
            })
            .catch(() => {
              // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
            });
          }
          const compiledAnswer = {
            questionId: element.id,
            title: element.title,
            answer: element.answer,
            comments: element.comments
          }
          action.questionsAndAnswers.push(compiledAnswer);
        });
        if (this.additionalReviewArr) {
          this.additionalReviewArr.forEach(element => {
            if (element.answer === 'yes' && element.comments.length < 1) {
              this.preventNext = true;
              this.notificationDialogService.confirm('Unable to proceed', '', 'Please add a comment for each Additional Feedback Question answered with Yes.', 'Ok', '400px')
              .then((confirmed) => {
                // if confirmed === true - user confirmed, if false - user clicked cancel button
                if (confirmed) {
                  // console.log('confirm leave');
                } else {
                  // console.log('do not confirm leave');
                };
              })
              .catch(() => {
                // user dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog))
              });
            }
            if (element.answer === 'no') {
              element.answer = null;
            };
            const compiledAnswer = {
              questionId: element.id,
              title: element.title,
              answer: element.answer,
              comments: element.comments
            }
            action.questionsAndAnswers.push(compiledAnswer);
          });
        };
        break;
      case 10:
        // exit
        break;
    }
    if (!this.preventNext) {
      this.adminReviewService.PerformActionOnAdminReview(this.reviewId, action).pipe(take(1))
      .subscribe(
        (response: any) => {
          if (response.success === true) {
            switch (actionName) {
              case 'Discard':
                if (this.storedState && this.reviewId === this.storedState.reviewId) {
                  this.removeStoredState();
                }
                this.router.navigate([`${this.stageUrl}`]);
                break;
              case 'Next':
                this.router.navigate([`${this.stageUrl}/review/${this.reviewId}/overview`]);
                break;
              case 'Exit':
                // exit
                this.router.navigate([`${this.stageUrl}`]);
                break;
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  async getReviewDetails(reviewId) {
    const response: any = await this.adminReviewService.GetAdminReviewReviewDetails(reviewId).toPromise();
    this.userAppNumber = response.userNumber;
    if (response.data) {

      if (this.storedState && this.storedState.reviewId === this.reviewId) {
        // if stored state exists and reviewId is equal to stored reviewId
        const now = DateTime.fromISO(DateTime.fromJSDate(new Date()).setZone('UTC').toISO());
        const diffInHours: number = now.diff(DateTime.fromISO(this.storedState.storeDate), 'hours').toObject().hours;

        if (diffInHours < 36) {
          // if stored state is less than 36 hours old - load info from stored state
          this.orderQuestions(this.storedState.allQuestions);
        } else {
          // if stored state is more than 36 hours old - load info from API and remove stored state
          this.removeStoredState();
          this.orderQuestions(response.data);
        }
      } else {
        // if stored state doesn't exists or reviewId is not the same - load info from API
        this.orderQuestions(response.data);
      }
    }
  }

  orderQuestions(allQuestions: any[]) {
    // mapping general questions
    const generalQuestions: any[] = allQuestions.filter(element => {
      if (element.questionType === 'General Question') {
        return element;
      };
    });
    if (generalQuestions.length) {
      function compare(a: { sortOrder: number; }, b: { sortOrder: number; }) {
        if ( a.sortOrder < b.sortOrder ){
          return -1;
        }
        if ( a.sortOrder > b.sortOrder ){
          return 1;
        }
        return 0;
      }
      generalQuestions.sort(compare);
      generalQuestions.forEach(element => {
        if (element.comments === null) {
          element.comments = '';
        };
      });
      this.generalQuestionsArr = generalQuestions;
    };

    // mapping additional review
    const additionalReviewQuestions: any[] = allQuestions.filter(element => {
      if (element.questionType === 'Additional Review' || element.questionType == 'Exemplar') {
        return element;
      };
    });

    if (additionalReviewQuestions.length) {
      function compare(a: { sortOrder: number; }, b: { sortOrder: number; }) {
        if ( a.sortOrder < b.sortOrder ){
          return -1;
        }
        if ( a.sortOrder > b.sortOrder ){
          return 1;
        }
        return 0;
      }
      additionalReviewQuestions.sort(compare);
      additionalReviewQuestions.forEach(element => {
        if (element.comments === null) {
          element.comments = '';
        }
        if (element.answer) {
          if (element.answer === 'yes') {
            element.subAnswer = true;
          } else if (element.asnwer === 'no') {
            element.subAnswer = false;
          }
        } else {
          element.subAnswer = null;
        }
      });
      this.additionalReviewArr = additionalReviewQuestions;
    };
  }

  updateAnswer(question) {
    if (question.subAnswer) {
      question.answer = 'yes';
    } else {
      question.answer = 'no';
    }
    this.storeState();
  }

  markQuestion(questionIndex, answer) {
    this.generalQuestionsArr[questionIndex].answer = answer;

    this.storeState();
  }

  storeState() {
    const storedState: any = {
      reviewId: this.reviewId,
      storeDate: DateTime.fromJSDate(new Date()).setZone('UTC').toISO(),
      allQuestions: this.generalQuestionsArr.concat(this.additionalReviewArr)
    };
    this.storageService.store('adminReviewStoredState', storedState);
    this.storedState = storedState;
  }

  removeStoredState() {
    this.storageService.removeCompletely('adminReviewStoredState');
  }

  slideToNextQuestion(event, questionIndex, type?) {
    event.target.blur();
    if (type === 'ar') {
      document.getElementById(`additional-review-${questionIndex + 1}`).scrollIntoView({behavior: 'smooth'});
    } else {
      document.getElementById(`general-question-${questionIndex + 1}`).scrollIntoView({behavior: 'smooth'});
    }
  }

  goToViewMode() {

  }
}
