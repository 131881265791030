import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./reducers";
import { environment } from "src/environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { EntityDataModule, DefaultDataServiceConfig } from "@ngrx/data";
import { StoreRouterConnectingModule, RouterState } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

const NGRX_STORE_CONFIGURATION = {
  metaReducers,
  runtimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
    strictActionSerializability: true,
    strictStateSerializability: true,
  },
};

const REGISTERED_EFFECTS = [];

export const ENTITY_METADATA = {
};

export const ENTITY_PLURAL_NAMES = {
};

const NGRX_DATA_SERVICE_CONFIGURATION = {
};

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, NGRX_STORE_CONFIGURATION),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(REGISTERED_EFFECTS),
    EntityDataModule.forRoot({}),
    StoreRouterConnectingModule.forRoot({
      stateKey: "router",
      routerState: RouterState.Minimal,
    }),
  ],
  providers: [
    {
      provide: DefaultDataServiceConfig,
      useValue: NGRX_DATA_SERVICE_CONFIGURATION,
    }
  ],
})
export class AppStoreModule {}
