export const environment = {
  production: true,
  publicConfigUrl: '/api/public/configuration',
  localDevSsoRedirect: false,
  ssoLoginRedirectIntervalInHours: 24,

  sentry: {
    DSN: 'https://6761d6ef50034f2fafa4de0c36d6d1ff@o4504165036457984.ingest.sentry.io/4505527883399168',
    environment: 'prod',
    tracePropagationTargets: ["localhost", "https://rpqa20.rampitplatform.com/"],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  }
};
