import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';

import { NotificationBarComponent } from '../components/notification-bar/notification-bar.component';

interface NotificationBarInterface {
  show?: boolean;
  type?: string;
  content?: string;
  timeout?: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationBarService {

  private sub = new BehaviorSubject<NotificationBarInterface>({});

  constructor() { }

  public getSub() {
    return this.sub.asObservable();
  }

  public showNotification(type: 'update' | 'success' | 'warning' | 'alert', content: string, timeout?: number) {
    this.sub.next({show: true, type, content, timeout});
  }

  public hideNotification() {
    this.sub.next({show: false});
  }
}
