import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TabTitleService } from "../shared/services/tab-title.service";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"]
})
export class ErrorComponent implements OnInit {
  @Input() errorStatus: number;
  @Input() supportEmail: string;
  
  constructor(private router: Router, private tabTitleService: TabTitleService) {}
  mailto(emailAddress: string, emailSubject: any) {
    return "mailto:" + emailAddress + "?subject=" + emailSubject;
  }

  ngOnInit() {
    //   TODO: Remove once the API with forbidden status gets developed.
    //   This is set only temporarily, for testing purposes.
    this.tabTitleService.setTabTitle('Oops!');
    if (this.router.url.split("/").pop() == "403") {
      this.errorStatus = 403;
      this.supportEmail = "exampleemail@email.com";
    }
  }
}
