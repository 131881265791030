export interface IUserFormDataFilter {
  condition: string;
  rules: IUserFormDataFilterRule[];
}

export interface IUserFormDataFilterRule {
  field: string;
  operator: string;
  value: string;
}

export function createFromGridColumns(columns) {
  let filters = [];
  for (const col of columns.filter((c) => c.isUserFormData)) {
    let filter: IUserFormDataFilter = {
      condition: "",
      rules: [{ field: col.field, operator: "", value: "" }],
    };
    filters.push(filter);
  }
  return filters;
}

export function createFromGridFilters(columns, gridFilters) {
  let filters = [];
  for (const col of columns.filter((c) => c.isUserFormData)) {
    let filter: IUserFormDataFilter = {
      condition: "",
      rules: [],
    };
    let i = 0,
      gridFilter = gridFilters[col.field]?.[i];
    while (gridFilter) {
      filter.condition = gridFilter.operator;
      let filterRule: IUserFormDataFilterRule = {
        field: col.field,
        operator: gridFilter.matchMode,
        value: gridFilter.value,
      };
      filter.rules.push(filterRule);
      gridFilter = gridFilters[col.field][++i];
    }
    filters.push(filter);
  }
  return filters;
}
