<div #bar class="notification-bar" [ngClass]="type">
  <div class="content">
    <span class="icon-wrapper" [ngSwitch]="type">
      <span class="icon material-icons-round icon-update" *ngSwitchCase="'update'">info</span>
      <span class="icon material-icons-round icon-success" *ngSwitchCase="'success'">check_circle</span>
      <span class="icon material-icons-round icon-warning" *ngSwitchCase="'warning'">warning</span>
      <span class="icon material-icons-round icon-report" *ngSwitchCase="'alert'">report</span>
    </span>
    <div class="content-inner" [innerHTML]="content"></div>
  </div>
  <div class="close-button" (click)="hide()">
    <span class="icon material-icons-round">close</span>
  </div>
</div>