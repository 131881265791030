import { Component, OnInit } from "@angular/core";
import { FormioAppConfig } from "@formio/angular";
import { TabTitleService } from "src/app/shared/services/tab-title.service";
import { DataService } from '../../shared/services/data.service';

@Component({
  selector: "app-registration-edit",
  templateUrl: "./registration-edit.component.html",
  styleUrls: ["./registration-edit.component.scss"],
})
export class RegistrationEditComponent implements OnInit {
  public form: any;
  constructor(
    public config: FormioAppConfig,
    private data: DataService,
    private tabTitleService: TabTitleService
  ) {}

  ngOnInit() {
    this.tabTitleService.setTabTitle('Registration Form Edit');
    this.data.get(`${this.config.appUrl}/registration`).subscribe((form: any) => (this.form = form));
  }

  onChange(event: any) {
    this.data.put(`${this.config.appUrl}/form/${event.form._id}`, event.form).subscribe();
  }
}
