import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CompetitionInformation } from '../model/common.model'
import { PublicConfigService } from "./public.config.service";
import { Tokens } from "../model/auth.model";
import { StorageService } from "./storage.service";
import { tap } from 'rxjs/operators';
import { DateTime } from "luxon";


@Injectable(
  {providedIn: 'root'}
)
export class CommonService {

  public publicConfig: any;
  public competitionInfo = new BehaviorSubject<any>(null);

  constructor(private httpClient: HttpClient, publicConfigService: PublicConfigService, private storage: StorageService) {
    this.publicConfig  = publicConfigService;
  }


  GetCompetitionInformation() {
      return this.httpClient
      .get(`${this.publicConfig.rpBaseUrl}/api/competitionInformation`)
      .pipe(tap((response: any) => this.setLocalStorageCompetitionInfo(response)));
  }

  public setLocalStorageCompetitionInfo(response) {
    if (response.data.competitionName && this.storage.retrieve(CompetitionInformation.competitionName) !== response.data.competitionName) {
      this.storage.store(CompetitionInformation.competitionName, response.data.competitionName);
    }
    if (response.data.brandingsLogo && this.storage.retrieve(CompetitionInformation.brandingsLogo) !== response.data.brandingsLogo) {
      this.storage.store(CompetitionInformation.brandingsLogo, response.data.brandingsLogo);
    }
    if (response.data.brandingsLogoAltText && this.storage.retrieve(CompetitionInformation.brandingsLogoAltText) !== response.data.brandingsLogoAltText) {
      this.storage.store(CompetitionInformation.brandingsLogoAltText, response.data.brandingsLogoAltText);
    }
    if (response.data.brandingsFavicon && this.storage.retrieve(CompetitionInformation.brandingsFavicon) !== response.data.brandingsFavicon) {
      this.storage.store(CompetitionInformation.brandingsFavicon, response.data.brandingsFavicon);
    }
    if (response.data.brandingsLoginPageBackgroundImage && this.storage.retrieve(CompetitionInformation.brandingsLoginPageBackgroundImage) !== response.data.brandingsLoginPageBackgroundImage) {
      this.storage.store(CompetitionInformation.brandingsLoginPageBackgroundImage, response.data.brandingsLoginPageBackgroundImage);
    }
    if (response.data.loginWidgetLogoUrl && this.storage.retrieve(CompetitionInformation.loginWidgetLogoUrl) !== response.data.loginWidgetLogoUrl) {
      this.storage.store(CompetitionInformation.loginWidgetLogoUrl, response.data.loginWidgetLogoUrl);
    }
    if (response.data.timeZoneName && this.storage.retrieve(CompetitionInformation.timeZoneName) !== response.data.timeZoneName) {
      this.storage.store(CompetitionInformation.timeZoneName, response.data.timeZoneName);
    }
    if (response.data.timeZoneAbbr && this.storage.retrieve(CompetitionInformation.timeZoneAbbr) !== response.data.timeZoneAbbr) {
      this.storage.store(CompetitionInformation.timeZoneAbbr, response.data.timeZoneAbbr);
    }
    if (response.data.timeZoneDatabaseName && this.storage.retrieve(CompetitionInformation.timeZoneDatabaseName) !== response.data.timeZoneDatabaseName) {
      this.storage.store(CompetitionInformation.timeZoneDatabaseName, response.data.timeZoneDatabaseName);
    }
    if (response.data.supportEmailAddress && this.storage.retrieve(CompetitionInformation.supportEmailAddress) !== response.data.supportEmailAddress) {
      this.storage.store(CompetitionInformation.supportEmailAddress, response.data.supportEmailAddress);
    }
    
    this.competitionInfo.next(response);
  }

  public isRegistrationOpen(): boolean {

    const competitionInfo = this.competitionInfo.value.data
    const registrationEndDate = competitionInfo.registrationEndDate;

    return DateTime.fromISO(registrationEndDate) > DateTime.fromISO(new Date().toISOString());
  }

  getUserStageRank(row, field) {
    return row?.ranks?.filter(x => x.stageIdForDisplay === field?.replace('rank_', ''))[0]?.rank;
  }
}