<div class="fullscreen">
   
    <div class="container-table">

        <div class="header">
            <div class="heading">
                <h2>Reviewing: {{ participantName }}</h2>
            </div>
            <div class="actions">
                <app-dropdown [items]="formsArray" type="link" placeholderText="Submissions" optionLabel="name"></app-dropdown>
                <button *ngIf="!redistributionAlreadyRan" class="button button-secondary" [disabled]="isDeadlinePassed" (click)="edit()">Edit</button>
                <button class="button button-primary" (click)="exit()">Exit</button>
            </div>
        </div>

        <div class="content">
            <h5 class="table-title">Summary</h5>
            <div class="table-description" [innerHTML]="description | formatLinks"></div>

            <table class="summary-table">
                <thead>
                    <tr>
                        <th>Criteria</th>
                        <th>Score</th>
                        <th>Feedback</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sum of summary; let first = first; let last = last; let i = index">
                        <td>
                            <span>{{ sum.criteria }}</span>
                        </td>
                        <td>{{ sum.score }}</td>
                        <td>
                            <div class="summary-feedback-wrap" [ngClass]="{'expanded': sum.expanded === true}">
                                <span class="expand-trigger" *ngIf="sum.showFeedbackOverlay" (click)="expandFeedback(sum, i)">
                                    <span class="icon material-icons">expand_more</span>
                                </span>
                                <div class="summary-feedback" [ngClass]="{'behind-overlay': sum.showFeedbackOverlay === true}">
                                    <p>{{ sum.feedback }}</p>
                                </div>
                                <div class="summary-feedback-overlay" *ngIf="sum.showFeedbackOverlay">
                                    <p>{{ sum.feedback }}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>        
</div>    