import { Injectable } from '@angular/core';
import * as sanitizeHtml from 'sanitize-html';

@Injectable({
  providedIn: 'root'
})
export class SanitizeService {

  constructor() { }

  sanitizeUserInput(input: string) : string {
    const cleanInput =
    sanitizeHtml(input, {
        textFilter: function(text) {
            return text
                .replaceAll("&amp;", "&")
                .replaceAll("&lt;", "")
                .replaceAll("&gt;", "");
          }
      });
    return cleanInput;
  }
}
