import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NotificationBarService } from '../../services/notification-bar.service';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit {

  content: string;
  type: string;
  timeout = null;
  @ViewChild('bar', {static: true}) bar: ElementRef;

  constructor(private notificationBarService: NotificationBarService) { }

  ngOnInit() {
    this.notificationBarService.getSub().subscribe(data => {
      this.type = data.type;
      this.content = data.content;

      if (data.show) {
        this.show();
        if (data.timeout) {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.hide();
          }, data.timeout);
        } else if (data.timeout === undefined) {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.hide();
          }, 5000);
        }
      } else if(data.show === false) {
        this.hide();
      }
    });
  }

  public show() {
    const notificationBar = this.bar.nativeElement as HTMLElement;
    notificationBar.classList.add('shown');
    document.querySelector('.custom-navbar-outer-wrapper')?.classList.add('notification-shown');
  }

  public hide() {
    if (this.timeout) clearTimeout(this.timeout);
    const notificationBar = this.bar.nativeElement as HTMLElement;
    notificationBar.classList.remove('shown');
    document.querySelector('.custom-navbar-outer-wrapper')?.classList.remove('notification-shown');
  }

}
