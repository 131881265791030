import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { SsoAuthService } from "../services/sso-auth.service";
import { CommonService } from "../services/common.service";
import { DateTime } from "luxon";

@Injectable({
  providedIn: 'root'
})
export class MasterGuard implements CanActivate {

  constructor(
    private router: Router,
    private ssoAuthService: SsoAuthService,
    private commonService: CommonService
    ) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    return this.runChecks(route);
  }

  async runChecks(route) {
    const rpUser = await this.ssoAuthService.getCurrentRpUser();
    const compInfo = await this.commonService.competitionInfo.pipe(take(1)).toPromise();

    // if there is no logged in user
    if(rpUser === null) {
      if (route.routeConfig.path === '/login') {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    }
    
    // if the user is guest, he can't see any pages at the moment
    if (rpUser.currentRole.name === 'Guest') {
      if (route.routeConfig.path === 'guest') {
        return true;
      } else {
        this.router.navigate(['/guest']);
        return false;
      }
    }

    // check if consent is granted
    if (!rpUser.currentRole.consentGranted) {
      if (route.routeConfig.path === 'consent') {
        return true;
      } else {
        this.router.navigate(['/consent']);
        return false;
      }
    } else {
      if (route.routeConfig.path === 'consent') {
        this.router.navigate(['/dashboard']);
        return false;
      }
    }

    // if Participant accepted consent after registrationEndDate
    if (rpUser.currentRole.name === "Participant" && 
        rpUser.currentRole.consentGranted && 
        DateTime.fromISO(rpUser.createdAt) > DateTime.fromISO(compInfo.data.registrationEndDate)){
      if (route.routeConfig.path === 'registration-closed') {
        return true;
      } else {
        this.router.navigate(['/registration-closed']);
        return false;
      }
    }
    
    // check if Registration is submitted for Participants
    if (rpUser.currentRole.name === "Participant" && !rpUser.isRegistrationSubmitted){
      if (route.routeConfig.path === 'registration' || route.routeConfig.path === 'form/:stageId/display/:userId') {
        return true;
      } else {
        this.router.navigate(['/registration']);
        return false;
      }
    }

    // regular checks for all other users
    const only: any[] = route.data?.only;
    if (only && !only.includes(rpUser.currentRole.name)) {
      this.router.navigate(['/403']);
      return false;
    }

    return true;
  }
}
