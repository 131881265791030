import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { emailRegex } from 'src/app/shared/model/auth.model';
import { CompetitionInformation } from 'src/app/shared/model/common.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { SsoAuthDisplayService } from 'src/app/shared/services/sso-auth.display.service';
import { SsoAuthService } from 'src/app/shared/services/sso-auth.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TabTitleService } from 'src/app/shared/services/tab-title.service';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  competitionLogo = '';

  showLogin: boolean = false;  
  userEmail: string = '';

  emailRegex = emailRegex;

  errorMessage: string = '';
  showErrorMessage: boolean = false;

  loaderShown: boolean = false;
  formSubmitting: boolean = false;

  constructor(
    private storage: StorageService,
    private commonService: CommonService,
    private tabTitleService: TabTitleService,
    public ssoAuthService: SsoAuthService,
    public ssoAuthDisplayService: SsoAuthDisplayService,
  ) { }

  async ngOnInit() {
    this.tabTitleService.setTabTitle('Forgot Password');

    this.commonService.competitionInfo
      .pipe(filter(res => !!res))
      .subscribe(() => {
        const logoFromStorage = this.storage.retrieve(CompetitionInformation.loginWidgetLogoUrl);
        if (logoFromStorage !== null) {
          this.competitionLogo = logoFromStorage;
        };
        this.showLogin = true;
      });
  }

  keyDownHandler(e: KeyboardEvent) {
    if (['Enter', 'NumpadEnter'].includes(e.key)) {
      this.shouldSubmit();
    };
  }

  shouldSubmit() {
    // email check
    if (!this.userEmail.match(this.emailRegex)) {
      return;
    }

    this.onSubmit();
  }

  async onSubmit() {
    // check if we are currently submitting the form
    if (!this.formSubmitting) {
      // show loading animation
      this.loaderShown = true;
      this.formSubmitting = true;

      try
      {
        const response = await this.ssoAuthService.sendForgotPasswordEmail(this.userEmail);
        if (response.success) {

          this.ssoAuthDisplayService.showGenericScreen(
            "Email sent!",
            response.data.message,
            ["Back to Login"]
          )
        }
      }
      catch(err){
        this.ssoAuthDisplayService.handleApiError(err);
      }
      finally {
        // hide loading animation
        this.loaderShown = false;
        this.formSubmitting = false;
      }
    };
  }
}
