export const cookieConsentConfig: CookieConsent.CookieConsentConfig = {
  cookie: {
    domain: /(?<=\.).+/.exec(location.hostname)?.[0]
  },
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom left",
      flipButtons: false,
      equalWeightButtons: false,
    },
    preferencesModal: {
      layout: "box",
      flipButtons: false,
      equalWeightButtons: false,
    },
  },
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    analytics: {
      enabled: false,
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
            domain: /(?<=\.).+/.exec(location.hostname)?.[0],
          },
        ],
      },
    },
    marketing: {
      enabled: false,
      autoClear: {
        cookies: [
          {
            name: "marketing",
            domain: /(?<=\.).+/.exec(location.hostname)?.[0],
          },
        ],
      },
    },
  },
  language: {
    default: "en",
    translations: {
      en: {
        consentModal: {
          title: "Cookies",
          description: `When you accept, you agree to store cookies to help give you the best experience on out site. Navigate to Cookie Preferences in the website footer to change preferences at any time.`,
          acceptAllBtn: "Accept All Cookies",
          acceptNecessaryBtn: "",
          showPreferencesBtn: "Customize Cookies",
        },
        preferencesModal: {
          title: "Cookie Preferences Manager",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "",
          savePreferencesBtn: "Accept Selected Cookies ",
          closeIconLabel: "Close modal",
          sections: [
            {
              description: `When you accept cookies, you agree to store cookies on your device to help give you the best experience on our site. Go to Cookie Preferences in the website footer to change preferences any time.`,
            },
            {
              title: "Essential (always enabled)",
              description:
                "These cookies ensure that our website functions properly.",
              linkedCategory: "necessary",
            },
            {
              title: "Analytics",
              description:
                "These cookies help us analyze how visitors interact with our website so we can improve its performance.",
              linkedCategory: "analytics",
            },
            {
              title: "Marketing",
              description:
                "These cookies help us analyze the effectiveness of our marketing campaigns. They may also be used to deliver advertising that is more relevant to you on certain social media networks.",
              linkedCategory: "marketing",
            },
          ],
        },
      },
    },
  },
};

export const saveConsentToCloudFlare = async (
  categories: string[],
  action: "accept" | "change" = "accept",
  url: string,
) => {
  await fetch(
    url,
    {
      method: "POST",
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        id: Math.random().toString(36).replace("0.", "consent_"),
        action,
        url: window.location.href,
        userAgent: navigator.userAgent,
        consents: categories.toString(),
        bannerText: "standard",
      }),
    }
  );
};
