import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { FormioAppConfig } from "@formio/angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  FormDetails,
  formStageIdRouteParam,
  formUserIdRouteParam,
} from "../model/form-details";
import { FormDetailsService } from "./form-details.service";

@Injectable({
  providedIn: "root",
})
export class FormDisplayResolverService implements Resolve<FormDetails> {
  constructor(
    private formioConfig: FormioAppConfig,
    private formDetailsService: FormDetailsService
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<FormDetails> {
    const stageId = route.paramMap.get(formStageIdRouteParam);
    const userId = route.paramMap.get(formUserIdRouteParam);
    const formDetails$ = this.formDetailsService
      .getFormDetailsByStageId(stageId, userId)
      .pipe(
        map((formDetails): FormDetails => {
          formDetails.absolutePath = `${this.formioConfig.appUrl}/${formDetails.relativePath}`;
          return formDetails;
        })
      );
    return formDetails$;
  }
}
