import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { FormioAppConfig } from "@formio/angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FormDetails, formStageTypeRouteParam } from "../model/form-details";
import { FormDetailsService } from "./form-details.service";

@Injectable({
  providedIn: "root",
})
export class DefaultFormsSubmissionResolverService
  implements Resolve<FormDetails>
{
  constructor(
    private formioConfig: FormioAppConfig,
    private formDetailsService: FormDetailsService
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<FormDetails> {
    const stageType = route.data[formStageTypeRouteParam];
    const formDetails$ = this.formDetailsService
      .getFormDetailsByStageType(stageType)
      .pipe(
        map((formDetails) => {
          formDetails.absolutePath = `${this.formioConfig.appUrl}/${formDetails.relativePath}`;
          return formDetails;
        })
      );
    return formDetails$;
  }
}
