<button
  id="scrollTop"
  aria-pressed="false"
  tabindex="0"
  (click)="onScrollToTop()"
  class="scroll-wrapper">
  <span class="material-icons-round scroll-icon">
    north
  </span>
</button>
