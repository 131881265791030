<!-- logo part -->
<div class="logo-wrapper">
  <span class="loader loader-circle loader-logo" *ngIf="loaderLogo"></span>
  <a [routerLink]="['/']" class="logo-link" title="{{competitionName}}" *ngIf="!loaderLogo">
    <img id="main-logo" src="{{competitionLogo}}" alt="{{competitionLogoAltText}}" class="logo">
  </a>
</div>

<!-- navigation part -->
<div class="main-nav" *ngIf="(currentRpUser$ | async) as rpUser">
  <!-- left navigation -->
  <div class="nav-left" (clickOutside)="closeDropdownSubmission()">
    <span class="loader loader-line loader-nav-primary loader-double" *ngIf="loaderPrimaryNav"></span>
    <!-- wrapper for each navigation item -->
    <div class="btn-group navbarItem" *ngFor="let pList of PrimaryList; let i = index">
      <!-- primary navigation item if there are no children -->
      <div *ngIf="pList.children.length === 0">
        <!-- primary navigation item -->
        <span
          [routerLink]="[pList.navigationItemHref ? pList.navigationItemHref : router.url]"
          class="navbarItem"
        >
          {{pList.navigationItemName}}
        </span>
        <!-- end primary navigation item -->
      </div>
      <!-- end primary navigation item if there are no children -->
      <!-- primary navigation item if there are children! -->
      <div *ngIf="pList.children.length > 0">
        <!-- primary navigation item -->
        <span
        aria-haspopup="true"
        aria-expanded="false"
        class="navbarItem"
        [ngClass]="{ open: submissionIndex === i, 'navbar-icon': pList.children.length }"
        (click)="toggleSubmission(i)"
      >
        {{ pList.navigationItemName }}
      </span>
        <!-- end primary navigation item -->
        <!-- primary children items - if any -->
        <div class="dropdown-menu subDrop" *ngIf="pList.children.length" [ngClass]="{'show': submissionIndex === i}">
          <!-- scroll wrapper -->
          <div class="scroll-wrapper">
            <div class="gradientscroll">
              <!-- inner wrapper for children items -->
              <div class="main">
                <!-- wrapper for each child -->
                <div class="main_item"
                  (click)="toggleSubSubmission(j)"
                  *ngFor="let pListChild of pList.children; let j = index"
                >
                  <!-- inner wrapper for each child -->
                  <div class="flexBlock">
                    <!-- actual link item -->
                    <div [routerLink]="[pListChild.navigationItemHref]" (click)="closeDropdownSubmission()" routerLinkActive="is-active">
                      <div>
                        <mat-icon aria-hidden="true" class="icon-left">
                          {{pListChild.navigationItemIcon ? pListChild.navigationItemIcon : 'description'}}
                        </mat-icon>
                      </div>
                      <div><strong> {{pListChild.navigationItemName}} </strong></div>
                    </div>
                    <!-- end actual link item -->

                    <!-- child child items? -->
                    <div *ngIf="pListChild.children.length">
                      <span *ngIf="submissionSubIndex !== j" class="icon material-icons">
                        expand_more
                      </span>
                      <span *ngIf="submissionSubIndex === j" class="icon material-icons">
                        expand_less
                      </span>
                    </div>
                    <!-- end child child items? -->
                  </div>
                  <!-- end inner wrapper for each child -->

                  <!-- child child items? -->
                  <div class="block_Info" *ngIf="submissionSubIndex === j && pListChild.children.length">
                    <div class="item_group">
                      <div class="dropdown_item" [routerLink]="[pListSubChild.navigationItemHref]" *ngFor="let pListSubChild of pListChild.children; let k = index">
                        {{pListSubChild.navigationItemName}}
                      </div>
                    </div>
                  </div>
                  <!-- end child child items? -->
                </div>
                <!-- end wrapper for each child -->
              </div>
              <!-- end inner wrapper for children items -->
            </div>
          </div>
          <!-- end scroll wrapper -->
        </div>
        <!-- end primary children items - if any -->
      </div>
      <!-- end primary navigation item if there are children -->
    </div>
    <!-- end wrapper for each navigation item -->
  </div>
  <!-- end left navigation -->

  <!-- right navigation -->
  <div class="nav-right">
    <!-- globe menu -->
    <div class="btn-group btn-group-globe-menu">
      <span class="loader loader-circle loader-globe-menu" *ngIf="loaderGlobeMenu"></span>
      <div class="globe-menu"
        *ngIf="challengeSiteLinks.length"
        (clickOutside)="globeMenuSelected = false"
      >
        <!-- globe icon / dropdown trigger -->
        <mat-icon (click)="selectGlobMenu()" [ngStyle]="{'color': globeMenuSelected ? '#4E71FB' : '' }"  aria-hidden="false">language</mat-icon>
        <!-- end globe icon / dropdown trigger -->
        <!-- dropdown menu -->
        <div class="dropdown-menu" [ngClass]="{'show': globeMenuSelected}">
          <!-- scroll wrapper -->
          <div class="scroll-wrapper">
            <div class="gradientscroll">
              <div class="scroll-inner">
                <!-- dropdown menu item -->
                <a
                  class="dropdown-item"
                  [href]="challengeLink(link.href)"
                  *ngFor="let link of challengeSiteLinks"
                >
                  <mat-icon aria-hidden="false" >language</mat-icon>
                  <span class="dropdown-text">{{link.name}}</span>
                </a>
                <!-- end dropdown menu item -->
              </div>
            </div>
          </div>
          <!-- end scroll wrapper -->
        </div>
        <!-- end dropdown menu -->
      </div>
    </div>
    <!-- end globe menu -->

    <!-- notifications -->
    <div class="nav-right-iconBlock btn-group" *ngIf="false">
      <span class="loader loader-circle" *ngIf="loaderNotifications"></span>
      <mat-icon aria-hidden="false" *ngIf="!loaderNotifications">notifications</mat-icon>
    </div>
    <!-- end notifications -->

    <!-- profile menu -->
    <div
      class="btn-group nav-right-iconBlock userInfoMenu" 
      (clickOutside)="closeProfileMenu()"
    >
      <!-- profile menu icon / dropdown trigger -->
      <span
        aria-haspopup="true"
        aria-expanded="false"
        (click)="toggleProfileMenu()"
      >
        <mat-icon [ngClass]="{'userInfoMenuSelected': showProfileMenu}" aria-hidden="false" >account_circle</mat-icon>
      </span>
      <!-- end profile menu icon / dropdown trigger -->
      <!-- dropdown menu wrapper -->
      <div class="dropdown-menu avatar_dropdown" [ngClass]="{'showProfile': showProfileMenu}">
        <!-- avatar wrapper -->
        <div class="dropdown-avatar">
          <!-- avatar icon -->
          <div class="user-icon__block">
            <mat-icon  aria-hidden="false" >account_circle</mat-icon>
          </div>
          <!-- end avatar icon -->
          <!-- user info -->
          <div *ngIf="rpUser" class="text_block">
            <span class="dropdown-avatar_text">{{rpUser.firstName}} {{rpUser.lastName}}</span>
            <p class="dropdown-avatar_text">{{rpUser.currentRole.name}}</p>
          </div>
          <!-- end user info -->
        </div>
        <!-- end avatar wrapper -->

        <!-- change role dropdown -->
        <div class="change_role" [ngClass]="{'change_role_Selected': changeRoleSelected}" *ngIf="rpUser.roles.length > 1">
          <!-- change role text / icon -->
          <div class="change_role__text" (click)="changeRoleSelected = !changeRoleSelected">
            <div class="icon-wrap">
              <mat-icon  aria-hidden="false" >account_circle</mat-icon>
            </div>
            <strong class="item-text">Change Role</strong>
            <span *ngIf="!changeRoleSelected" class="icon material-icons">
              expand_more
            </span>
            <span *ngIf="changeRoleSelected" class="icon material-icons">
              expand_less
            </span>
          </div>
          <!-- end change role text / icon -->
          <!-- list of roles dropdown -->
          <div class="block_Info" *ngIf="changeRoleSelected">
            <div class="dropdown_item" *ngFor="let role of rpUser.roles; let i = index">
              <label class="container">{{role.name}}
                <input type="radio" [checked]="role.id == rpUser.currentRole.id" (click)="setRole(role.id)" name="radio" [disabled]="role.id == rpUser.currentRole.id">
                <span class="checkmark" ></span>
              </label>
            </div>
          </div>
          <!-- end list of roles dropdown -->
        </div>
        <!-- end change role dropdown -->

        <!-- account settings link -->
        <div class="main_item">
          <a href="{{accountSettingsURL}}" target="_blank">
            <mat-icon  aria-hidden="false" >settings</mat-icon><strong>Account Settings</strong>
          </a>
        </div>
        <!-- end account settings link -->
        <!-- logout button -->
        <div class="main_item" (click)="logout()">
          <mat-icon  aria-hidden="false" >logout</mat-icon><strong>Logout</strong>
        </div>
        <!-- end logout button -->
      </div>
      <!-- end dropdown menu wrapper -->
    </div>
    <!-- end profile menu -->

    <!-- help link -->
    <div class="nav-right-iconBlock btn-group" *ngIf="false">
      <span class="loader loader-circle" *ngIf="loaderHelp"></span>
      <mat-icon aria-hidden="false" *ngIf="!loaderHelp">help</mat-icon>
    </div>
    <!-- end help link -->

    <!-- secondary navigation -->
    <div
      class="btn-group nav-right-iconBlock"
      (clickOutside)="closeDropdownSidebar()"
    >
      <span class="loader loader-circle loader-sidebar" *ngIf="loaderSidebar"></span>
      <!-- secondary navigation icon / dropdown trigger -->
      <span
        aria-haspopup="true"
        aria-expanded="false"
        class="navbarItem"
        [ngStyle]="{'color': showSidebar ? '#5A7BFE' : null}"
        (click)="toggleSidebar()"
        *ngIf="SecondaryList?.length"
      >
        <div class="sidebar-menu" [ngClass]="{'active': showSidebar}">
          <div class="sidebar-menu-hamburger sidebar-menu-hamburger-top"></div>
          <div class="sidebar-menu-hamburger sidebar-menu-hamburger-middle"></div>
          <div class="sidebar-menu-hamburger sidebar-menu-hamburger-bottom"></div>
        </div>
      </span>
      <!-- end secondary navigation icon / dropdown trigger -->
      <!-- secondary navigation dropdown -->
      <div class="dropdown-menu sidebar-dropdown" [class.show]="showSidebar">
        <!-- inner wrapper -->
        <div class="main">
          <!-- wrapper for each child item -->
          <div class="wrapper" *ngFor="let secList of SecondaryList; let i = index" [ngClass]="{'divider-wrap': secList.navigationItemName === 'Forums' || secList.navigationItemName === 'Dashboard'}">
            <!-- if there ARE NO children, this will be rendered -->

            <div class="divider" *ngIf="secList.navigationItemName === 'Forums' || secList.navigationItemName === 'Dashboard'"></div>

            <div
              class="main_item" 
              *ngIf="secList.children.length === 0"
              [routerLink]="[secList.navigationItemHref ? secList.navigationItemHref : null]"
              [ngClass]="{'main_itemSelected': chooseUrl(secList.navigationItemName) && secondaryNavIndex !== i, 'expanded': secondaryNavIndex === i}"
            >
              <div class="flexBlock">
                <div>
                  <!-- icon wrapper -->
                  <span class="icon-wrap">
                    <mat-icon fontSet="material-icons-round" aria-hidden="true" [ngStyle]="{'color': chooseUrl(secList.navigationItemName) ? '#5A7BFE' : null}">
                      {{secList.navigationItemIcon ? secList.navigationItemIcon : 'description'}}
                    </mat-icon>
                  </span>
                  <!-- end icon wrapper -->
                  <!-- text wrapper -->
                  <strong class="item-text">{{secList.navigationItemName}}</strong>
                  <!-- end text wrapper -->
                </div>
              </div>
            </div>

            <!-- if there ARE children, this will be rendered -->
            <div
              class="main_item" 
              *ngIf="secList.children.length > 0"
              [ngClass]="{'main_itemSelected': chooseUrl(secList.navigationItemName) && secondaryNavIndex !== i, 'expanded': secondaryNavIndex === i}"
            >
              <!-- parent element -->
              <div class="flexBlock" (click)="toggleSecondary(i)">
                <div>
                  <span class="icon-wrap" >
                    <mat-icon fontSet="material-icons-round" aria-hidden="true" [ngStyle]="{'color': chooseUrl(secList.navigationItemName) ? '#5A7BFE' : null}">
                      {{secList.navigationItemIcon ? secList.navigationItemIcon : 'description'}}
                    </mat-icon>
                  </span>
                  <strong class="item-text">{{secList.navigationItemName}}</strong>
                </div>
                <div class="icon-wrap-arrow">
                  <span *ngIf="secondaryNavIndex !== i" class="icon material-icons">
                    expand_more
                  </span>
                  <span *ngIf="secondaryNavIndex === i" class="icon material-icons">
                    expand_less
                  </span>
                </div>
              </div>
              <!-- end parent element -->
              <!-- child wrapper -->
              <div class="block_Info" *ngIf="secondaryNavIndex === i && secList.children.length">
                <!-- child link wrapper / active link -->
                <div
                  class="dropdown_item"
                  [ngClass]="{'dropdown_itemSelected': secSubList.navigationItemHref === compiledURL}"
                  *ngFor="let secSubList of secList.children; let j = index" (click)="toggleSubSecondary(j)"
                >
                  <!-- actual link -->
                  <a
                    [routerLink]="[secSubList.navigationItemHref]"
                    [ngStyle]="{'color': secSubList.navigationItemHref === compiledURL ? '#5A7BFE' : null}"
                  >
                    {{secSubList.navigationItemName}}
                  </a>
                  <!-- end actual link -->
                </div>
                <!-- end child link wrapper / active link -->
              </div>
              <!-- end child wrapper -->
            </div>
          </div>
          <!-- end wrapper for each child item -->
        </div>
        <!-- end inner wrapper -->
      </div>
      <!-- end secondary navigation dropdown -->
    </div>
    <!-- end secondary navigation -->
  </div>
  <!-- end right navigation -->
</div>
<!-- end navigation part -->