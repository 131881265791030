<span class="p-float-label">
  <p-multiSelect
    [options]="options"
    [optionLabel]="optionLabel"
    [defaultLabel]="defaultLabel"
    [maxSelectedLabels]="maxSelectedLabels"
    [(ngModel)]="data"
    (onChange)="emitChange($event)"
    optionDisabled="inactive"
  >
  </p-multiSelect>
  <label *ngIf="labelName" [for]="id">{{ labelName }}</label>
</span>
