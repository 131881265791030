<label class="generic-label generic-label-checkbox" [ngClass]="{'disabled': disabled}">
  <input
    type="checkbox"
    class="generic-input-checkbox"
    (change)="onStateChange()"
    [(ngModel)]="state"
    [disabled]="disabled"
  >
  <span class="generic-checkbox">
    <span class="checkbox-inner">
      <span class="checkmark material-icons">check</span>
    </span>
    <span class="title">{{label}}</span>
  </span>
</label>