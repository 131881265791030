<label class="selected-label">Selected: </label>
<div class="choices" data-type="text" [ngClass]="{'has-empty-value': isControlEmpty, 'is-disabled': disabled}">
  <div class="choices__inner">
    <div class="choices__list choices__list--multiple" tabindex="-1">
      <div *ngFor="let node of selected" class="choices__item choices__item--selectable" data-deletable=""
        (click)="expandNode(node)">
        {{ node.label }}
        <button *ngIf="!disabled" (click)="unselectNode(node)" type="button" class="choices__button">
          Remove item
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showMaxSelectionError" class="error">
  Maximum number of allowed selections reached
</div>
<div class="tree-wrapper" *ngIf="!disabled">
  <div *ngIf="!disabled" class="button-container">
    <button class="button button-primary button-primary--medium" type="button" (click)="expandAll()"
      [disabled]="!canExpand()">
      <span class="icon material-icons">add</span>
      <span class="txt">Expand All</span>
    </button>
    <button class="button button-primary button-primary--medium" type="button" (click)="collapseAll()"
      [disabled]="!canCollapse()">
      <span class="icon material-icons">remove</span>
      <span class="txt">Collapse All</span>
    </button>
    <button class="button button-primary button-primary--medium" type="button" (click)="clearSelection()"
      [disabled]="!canClearSelection()">
      <span class="icon material-icons">refresh</span>
      <span class="txt">Clear All Selections</span>
    </button>
  </div>
  <p-tree *ngIf="!disabled" [loading]="loading$ | async" [value]="nodes" [selectionMode]="'checkbox'"
    [filter]="showFilter" (onFilter)="treeFiltered($event)" [selection]="selected"
    (onNodeSelect)="selectNode($event.node)" (onNodeUnselect)="unselectNode($event.node)"
    (onNodeCollapse)="collapseNode($event.node)" [filterPlaceholder]="'Search'">
    <ng-template let-node pTemplate="default">
      <span [ngClass]="{ dot: showDot(node) }"></span>
      <span (click)="nodeClicked(node)" [ngClass]="{
          'node-not-allowed': !node.selectable && !isSelectionDisabled(node),
          'node-clickable': node.children.length,
          'node-selection-disabled': isSelectionDisabled(node)
        }" [title]="node.label">{{ node.label }}</span>
    </ng-template>
  </p-tree>
</div>