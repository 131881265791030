<p-fileUpload
  #formFileUpload
  name="myfile[]"
  customUpload="true"
  (onSelect)="onSelect($event)"
  (uploadHandler)="handleUpload($event)"
  (onClear)="onClear($event)"
  accept="{{ getAllowedFileType() }}"
  maxFileSize="{{ getAllowedMaxSize() }}"
  invalidFileTypeMessageDetail="{{ getAllowedTypeMessage() }}"
  invalidFileSizeMessageDetail="{{ getMaxFileSizeMessage() }}"
  [disabled]="uploadInProgress || disabled"
  [ngClass]="{'disabled': disabled, 'has-empty-value': isControlEmpty}"
>
  <ng-template let-file pTemplate="file">
    <div *ngIf="showFileUploadSection">
      <div class="file-wrapper">
        <div class="file-info-wrap">
          <span class="file-name-wrap"><span class="file-name">{{ file.name }}</span></span>
          <span class="file-size-wrap"><span class="file-size">{{ fileSizeToDisplay }}</span></span>
        </div>
        <div class="buttons-wrap">
          <span class="upload-text">File upload in progress...</span>
          <p-button styleClass="button-progress--file-upload" [disabled]="uploadInProgress">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </p-button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template let-file pTemplate="content">
    <div class="initial-content" *ngIf="!showFileUploadSection && !fileUrl" (click)="clickOnSelectButton()">
      <span class="icon material-icons-outlined">upload</span>
      <div class="content-inside">Drag &amp; Drop or <span class="button-browse">browse</span><span *ngIf="fileUrl"> to choose another</span></div>
    </div>
    <div class="uploaded-file-wrap" *ngIf="fileUrl">
      <div class="uploaded-file">
        <div class="file-info">
          <a class="uploaded-file-link" *ngIf="fileUrl" [href]="fileUrl" target="_blank">
            {{ fileNameToDisplay }}
          </a>
          <span class="file-size-wrap"><span class="file-size">{{ fileSizeToDisplay }}</span></span>
        </div>
        <div class="buttons-wrap">
          <span class="uploaded-text" *ngIf="uploadedNow">File uploaded successfully!</span>
          <button
            class="button-delete--file-upload"
            (click)="onClear($event)"
            pTooltip="Delete file"
            tooltipPosition="bottom"
            [disabled]="uploadInProgress"
            *ngIf="!disabled"
          >
            <span class="icon material-icons">delete</span>
            <span class="text">Delete</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</p-fileUpload>