<div class="mark-final-modal" #refMarkFinalModal *ngIf="review">
  <div class="mark-final-modal-content">
    <div class="modal-heading">
      <h2>Mark Final</h2>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="status === 'Valid' || turndownReasons.length === 0">
        <div class="comment-wrap">
          <label for="mark-final-valid" class="general-setup-label">
            <span class="input-direct-wrap">
              <textarea
                type="text"
                id="mark-final-valid"
                class="general-setup-input"
                [(ngModel)]="review.isFinalComment"
                maxlength="256"
              >
              </textarea>
              <span class="placeholder general-setup-placeholder" [ngClass]="{'filled': review.isFinalComment}">Add final comment (optional)</span>
            </span>
          </label>
        </div>
      </ng-container>
      <ng-container *ngIf="status === 'Invalid' && turndownReasons.length > 0">
        <multi-select
          [options]="turndownReasons"
          optionLabel="name"
          labelName="Select one or more turndown reasons"
          [data]="selectedTurndownReasons"
          (change)="selectedTurndownReasons = $event"
        ></multi-select>
        <div class="comment-wrap">
          <label for="mark-final-turndown-comment" class="general-setup-label">
            <span class="input-direct-wrap">
              <textarea
                type="text"
                id="mark-final-turndown-comment"
                class="general-setup-input"
                [(ngModel)]="review.turndownComment"
                maxlength="256"
              >
              </textarea>
              <span class="placeholder general-setup-placeholder" [ngClass]="{'filled': review.turndownComment}">Add turndown comment (required)</span>
            </span>
          </label>
          <label for="mark-final-invalid" class="general-setup-label">
            <span class="input-direct-wrap">
              <textarea
                type="text"
                id="mark-final-invalid"
                class="general-setup-input"
                [(ngModel)]="review.isFinalComment"
                maxlength="256"
              >
              </textarea>
              <span class="placeholder general-setup-placeholder" [ngClass]="{'filled': review.isFinalComment}">Add final comment (optional)</span>
            </span>
          </label>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <div class="buttons-container" *ngIf="status === 'Valid' || turndownReasons.length === 0">
        <button class="button button-secondary" (click)="closeMarkFinalModal()">Cancel</button>
        <button class="button button-primary" (click)="performAction(8, 'Mark Final', true)">Mark Final</button>
      </div>
      <div class="buttons-container" *ngIf="status === 'Invalid' && turndownReasons.length > 0">
        <button class="button button-secondary" (click)="closeMarkFinalModal()">Cancel</button>
        <button class="button button-primary" [disabled]="!review.turndownComment || selectedTurndownReasons.length < 1" (click)="performAction(8, 'Mark Final', true)">Mark Final</button>
      </div>
    </div>
  </div>
</div>
<div class="fullscreen-modal fullscreen-modal--view">
  <div class="modal-header">
    <div class="container-md">
      <div class="grid">
        <div class="col-md-6">
          <div class="user-details">
            <h2 *ngIf="userFirstName && userLastName" class="user-name">Applicant: {{userFirstName}} {{userLastName}}</h2>
          </div>
        </div>
        <div class="col-md-6">
          <div class="review-details">
            <div *ngIf="userForms" class="dropdown-wrap">
              <app-dropdown
                [items]="userForms"
                type="link"
                placeholderText="Submissions"
                optionLabel="name"
              ></app-dropdown>
            </div>
            <div *ngIf="userActionsHeader" class="actions-wrap">
              <ng-container *ngFor="let action of userActionsHeader">
                <span class="actions-inner-wrap">
                  <button
                    class="button"
                    [ngClass]="{'button-secondary': action.name !== 'Exit' && action.name !== 'Submit',
                              'button-primary': action.name === 'Exit' || action.name === 'Submit'}"
                    (click)="performAction(action.value, action.name)"
                  >
                    {{action.name}}
                  </button>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-content">
    <div class="container-md">
      <div
        class="container-inner"
        [ngClass]="{'valid': status === 'Valid', 'invalid': status === 'Invalid', 'discarded': status === 'Discarded', 'flagged': status === 'Flagged'}"
      >
        <div class="badge" *ngIf="status === 'Valid' || status === 'Invalid' || status === 'Flagged'">
          <img *ngIf="status === 'Valid'" src="../../../../../assets/images/badge-valid.png">
          <img *ngIf="status === 'Invalid'" src="../../../../../assets/images/badge-invalid.png">
          <img *ngIf="status === 'Flagged'" src="../../../../../assets/images/badge-flagged.png">
        </div>
        <div class="content-header">
          <h1 class="application-title">Application #: <span *ngIf="userAppNumber" class="application-number">{{userAppNumber}}</span></h1>
        </div>
        <div class="questions-wrap">
          <div class="questions-info">
            <div class="row">
              <div class="col-md-6">
                <h4># Question</h4>
              </div>
              <div class="col-md-1">
                <h4>Answer</h4>
              </div>
              <div class="col-md-5">
                <h4>Comment</h4>
              </div>
            </div>
          </div>
          <div class="question-section" *ngIf="generalQuestionsArr">
            <h2 class="question-section-title">General Questions</h2>
            <div *ngIf="generalQuestionsArr" class="questions-inner-wrap">
              <div *ngFor="let question of generalQuestionsArr; let i = index">
                <div class="question-single question-general" [ngClass]="{'expanded': question.expanded === true}">
                  <div class="expand-trigger" *ngIf="question.showDescriptionOverlay || question.showCommentOverlay" (click)="toggleQuestionDetails(i, 'General Questions')">
                    <span class="icon material-icons">expand_more</span>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="question-description-wrap">
                        <span class="question-number">{{question.sortOrder + 1}}. </span>
                        <div class="question-description" [ngClass]="{'behind-overlay': question.showDescriptionOverlay === true}" [innerHTML]="question.description | formatLinks"></div>
                        <div class="question-description-overlay" *ngIf="question.showDescriptionOverlay" [innerHTML]="question.description | formatLinks"></div>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <div class="question-answer" [ngClass]="{'red': question.answer === 'no'}" [innerHTML]="question.answer"></div>
                    </div>
                    <div class="col-md-5">
                      <div class="question-comment-wrap">
                        <div class="question-comment" [ngClass]="{'behind-overlay': question.showCommentOverlay === true}">
                          <p>{{question.comments}}</p>
                        </div>
                        <div class="question-comment-overlay" *ngIf="question.showCommentOverlay">
                          <p>{{question.comments}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="question-section additional-spacing" *ngIf="additionalReviewArr">
            <h2 class="question-section-title">Additional Feedback</h2>
            <div *ngIf="additionalReviewArr" class="questions-inner-wrap">
              <div *ngFor="let question of additionalReviewArr; let i = index">
                <div class="question-single question-additional" [ngClass]="{'expanded': question.expanded === true}">
                  <div class="expand-trigger" *ngIf="question.showDescriptionOverlay || question.showCommentOverlay" (click)="toggleQuestionDetails(i, 'Additional Review')">
                    <span class="icon material-icons">expand_more</span>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="question-description-wrap">
                        <span class="question-number" *ngIf="i === 0">A. </span>
                        <span class="question-number" *ngIf="i === 1">B. </span>
                        <div class="question-description" [ngClass]="{'behind-overlay': question.showDescriptionOverlay === true}" [innerHTML]="question.description | formatLinks"></div>
                        <div class="question-description-overlay" *ngIf="question.showDescriptionOverlay" [innerHTML]="question.description | formatLinks"></div>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <div class="question-answer" [ngClass]="{'red': question.answer === 'yes' && question.questionType === 'Additional Review'}" [innerHTML]="question.answer ? question.answer : 'No'"></div>
                    </div>
                    <div class="col-md-5">
                      <div class="question-comment-wrap">
                        <div class="question-comment" [ngClass]="{'behind-overlay': question.showCommentOverlay === true}">
                          <p>{{question.comments}}</p>
                        </div>
                        <div class="question-comment-overlay" *ngIf="question.showCommentOverlay">
                          <p>{{question.comments}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-footer">
          <div *ngIf="userActionsFooter" class="actions-wrap">
            <ng-container *ngFor="let action of userActionsFooter">
              <span class="actions-inner-wrap">
                <button
                  class="button"
                  [ngClass]="{'button-secondary': action.name !== 'Submit' && action.name !== 'Mark Final' && action.name !== 'Exit',
                              'button-primary': action.name === 'Submit' || action.name === 'Mark Final' || action.name === 'Exit'}"
                  (click)="performAction(action.value, action.name)"
                >
                  {{action.name}}
                </button>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>