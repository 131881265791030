import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { RegistrationComponent } from "./registration/registration.component";
import { ApplicationComponent } from "./application/application.component";
import { FormDisplayComponent } from "./form-display/form-display.component";
import { FormSubmissionComponent } from "./form-submission/form-submission.component";
import { FormioCustomComponentsModule } from "../core/formio-custom-components/formio-custom-components.module";
import { ConsentComponent } from "./consent/consent.component";
import { FormSubmissionDataService } from "./services/form-submissions-data.service";
import { TooltipModule } from "primeng/tooltip";
import { ProgressBarModule } from "primeng/progressbar";
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { FormRendererComponent } from './form-renderer/form-renderer.component';
import { FormioAppConfig } from "@formio/angular";
import { FormDisplayRendererComponent } from "./form-display-renderer/form-display-renderer.component";

@NgModule({
  declarations: [
    RegistrationComponent,
    ApplicationComponent,
    ConsentComponent,
    FormDisplayComponent,
    FormSubmissionComponent,
    FormBuilderComponent,
    FormRendererComponent,
    FormDisplayRendererComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormioCustomComponentsModule,
    TooltipModule,
    ProgressBarModule,
  ],
  providers: [
    FormSubmissionDataService,
    {
      provide: FormioAppConfig,
      useValue: {
        appUrl:'',
        apiUrl: 'api',
      }
    }
  ],
})
export class FormsModule {}
